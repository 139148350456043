import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { default as jwtDecode, default as jwt_decode } from 'jwt-decode';
import { Observable, map, of } from 'rxjs';
import { StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import { STORAGE_JWT } from '../constants/auth.constant';
import { AuthUserRequestDto } from '../models/auth-user-request.dto';
import { AuthGoogleService } from './auth-google.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    constructor(
        private readonly authGoogleService: AuthGoogleService,
        private readonly storageService: StorageService,
        private httpClient: HttpClient,
        private router: Router,
    ) { }

    async authenticate() {
        await this.authGoogleService.loginWithGoogle();
    }

    validateToken(): Observable<any> {
        const isAuthenticated = this.authGoogleService.isAuthenticated();
        if (!isAuthenticated) {
            return of(null);
        }
        return this.getValidateToken();
    }

    getValidateToken(): Observable<any> {
        const request: AuthUserRequestDto = { token: this.authGoogleService.getTokenId() || '' };
        return this.httpClient.post(`${environment.URL_API_PAYROLL}/auth`, request).pipe(
            map(resp => resp)
        );
    }

    isLoggedIn(): boolean {
        const token = this.storageService.get(STORAGE_JWT) ?? '';
        if (token) {
            const decodedToken: any = jwt_decode(token);
            const expirationTime = decodedToken.exp * 1000;
            const now = Date.now();
            if (expirationTime > now) {
                return true;
            }
        }
        return false;
    }

    getDataUser(): any {
        const token = this.storageService.get(STORAGE_JWT) ?? '';
        const tokenPayload = jwtDecode(token);
        return tokenPayload;
    }

    logout() {
        this.storageService.clearAllData();
        this.authGoogleService.logOutGoogle();
        this.router.navigate(['/login']);
        window.location.reload();
    }
}