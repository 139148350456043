import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  protected storage = localStorage;

  constructor() { }

  get(key: string) {
    return this.storage.getItem(key);
  }

  set(key: string, data: any, parse = true): void {
    this.storage.setItem(key, parse ? JSON.stringify(data) : data);
  }

  remove(key: string) {
    this.storage.removeItem(key);
  }

  removeListKeys(listKeys: string[]) {
    listKeys.forEach((key: string) => {
      this.remove(key);
    });
  }

  deleteAllLocalStorage() {
    sessionStorage.clear();
    localStorage.clear();
  }

  clearAllData() {
    localStorage.clear();
    sessionStorage.clear();
  }
}
