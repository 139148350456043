import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    constructor(private authenticationService: AuthenticationService, private router: Router) { }
    canActivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (!this.authenticationService.isLoggedIn()) {
            this.router.navigate(['/login']);
            return false;
        }
        // logged in, so return true.
        this.authenticationService.isLoggedIn();
        return true;
    }
}
