import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage.service';
import Swal from 'sweetalert2';
import { STORAGE_JWT } from '../login/constants/auth.constant';
import { AuthenticationService } from '../login/services/authentication.service';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.accessValidate();
  }

  validateToken() {
    this.authenticationService.validateToken().subscribe({
      next: response => {
        if (response) {
          this.storageService.set(STORAGE_JWT, response.accessToken, false);
          this.router.navigate(['/home']);
        } else {
          this.showMessageUnauthorized();
        }
      },
      error: error => {
        this.showMessageUnauthorized();
      }
    });
  }

  showMessageUnauthorized() {
    Swal.fire({
      text: 'Usted no esta autorizado para acceder al sitio',
      timerProgressBar: true,
      timer: 3000,
      icon: 'error',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        this.router.navigate(['/login']);
      }
    });
  }

  accessValidate() {
    Swal.fire({
      text: 'Validando datos...',
      timerProgressBar: true,
      timer: 2500,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        this.validateToken();
      }
    });
  }
}
