import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard {
    constructor(private authenticationService: AuthenticationService, private router: Router) { }
    canActivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.authenticationService.isLoggedIn()) {
            this.router.navigate(['/home']);
            return false;
        }

        !this.authenticationService.isLoggedIn();
        return true;
    }
}
