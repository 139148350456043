import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/shared/services/storage.service';
import { STORAGE_JWT } from '../constants/auth.constant';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private storageService: StorageService,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.storageService.get(STORAGE_JWT);

        if (request.url.includes('/payroll')) {
            // headers: request.headers.set('Authorization', `Bearer ${token}`)
            const authReq = request.clone({
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                })
            });
            return next.handle(authReq);
        } else {
            return next.handle(request);
        }

    }

}
