import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthConfigService } from './auth-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGoogleService {

  constructor(
    private oAuthService: OAuthService,
    private authConfigService: AuthConfigService
  ) {
    this.configureOAuth();
  }

  loginWithGoogle() {
    this.oAuthService.initLoginFlow();
  }

  async configureOAuth() {
    const oAuthConfig = this.authConfigService.authConfig;

    this.oAuthService.configure(oAuthConfig);
    this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => { });
  }


  isAuthenticated() {
    return this.oAuthService.hasValidAccessToken();
  }

  getAccessToken() {
    return this.oAuthService.getAccessToken();
  }

  getTokenId() {
    return this.oAuthService.getIdToken();
  }

  loadUserProfile() {
    return this.oAuthService.loadUserProfile();
  }

  logOutGoogle() {
    this.oAuthService.logOut();
  }

}