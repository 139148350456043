import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigService {

  constructor() { }

  readonly authConfig: AuthConfig = {
    issuer: 'https://accounts.google.com',
    strictDiscoveryDocumentValidation: false,
    redirectUri: window.location.origin + environment.URL_REDIRECT_AUTH,
    clientId: environment.CLIENT_ID,
    scope: 'openid profile email'
  };
}
